export const dialogConfig = {
  formNewFill: {
    panelClass: "no-padding-container",
    maxWidth: "50vw",
    maxHeight: "100vh",
  },
  formEdit: {
    width: "1200px",
    height: "90vh",
    maxHeight: "95vh",
    panelClass: "no-padding",
  },
  sendEmail: {
    width: "70vw",
    maxWidth: "1110px",
    maxHeight: "98vh",
  },
  default: {
    panelClass: "default",
    width: "60%",
    maxWidth: "600px",
  },
  mdDialog: {
    panelClass: "default",
    width: "740px",
    minHeight: "450px",
  },
  confirm: {
    panelClass: "default-mdc-flat",
    width: "55%",
    maxWidth: "600px",
  },
  addDialogPermissions: {
    autoFocus: false,
    panelClass: "default-borderless",
    width: "888px",
  },
  addDialog: {
    autoFocus: false,
    panelClass: "default-borderless",
    width: "888px",
    maxHeight: "90vh",
  },
  addDialogInspection: {
    autoFocus: false,
    panelClass: "default-borderless",
    width: "936px",
    height: "95vh",
    maxHeight: "95vh",
  },
  addDialogArcitectural: {
    autoFocus: false,
    panelClass: "default-borderless",
    width: "888px",
    maxHeight: "95vh",
  },
  addDialogViolation: {
    autoFocus: false,
    panelClass: "default-borderless",
    width: "800px",
    maxHeight: "95vh",
  },
  addDialogStd: {
    autoFocus: false,
    panelClass: "default-borderless",
    width: "888px",
    maxHeight: "95vh",
  },
  addDialogMd: {
    autoFocus: false,
    panelClass: "default-borderless",
    width: "626px",
  },
  addDialogBorder: {
    panelClass: "default-mdc-flat",
    width: "55%",
    maxWidth: "600px",
  },
  dialogPermissions: {
    panelClass: "default-mdc-flat",
    width: "55%",
    maxWidth: "700px",
  },
  confirmDialog: {
    panelClass: "default-mdc-flat",
    width: "55%",
    maxWidth: "600px",
    position: { top: "75px" },
  },
  confirmDialogCenter: {
    panelClass: "default-mdc-flat",
    width: "55%",
    maxWidth: "700px",
  },
  scheduleDialog: {
    autoFocus: false,
    panelClass: "default-borderless",
    width: "80vw",
    maxWidth: "1200px",
  },
  financeDialogUpdate: {
    panelClass: "financial-panel",
    width: "95vw",
    height: "100%",
    maxWidth: "1114px",
    maxHeight: "95vh",
  },
  updateDialog: {
    width: "1200px",
    height: "90vh",
    maxHeight: "810px",
    panelClass: "no-padding",
  },
  listDialog: {
    autoFocus: false,
    panelClass: "default-mdc-flat",
    width: "600px",
    height: "768px",
    minHeight: "50vh",
    maxHeight: "98vh",
  },
  full: {
    panelClass: "fullpage",
    width: "100vw",
    height: "100vh",
  },
  dialogStd: {
    panelClass: "default-mdc-flat",
    width: "560px",
  },
  dialogMdcOne: {
    panelClass: "default-mdc",
    minWidth: "1000px",
    maxWidth: "1152px",
    maxHeight: "98vh",
  },
  dialogSm: {
    autoFocus: false,
    panelClass: "default-borderless",
    width: "55vw",
    maxWidth: "645px",
  },
  dialogMd: {
    autoFocus: false,
    panelClass: "default-borderless",
    width: "85vw",
    maxWidth: "724px",
  },
  formFill: {
    autoFocus: false,
    panelClass: "default-borderless",
    width: "90vw",
    minHeight: "98vh",
    maxWidth: "888px",
  },
  lgDialogBorder: {
    width: "90vw",
    maxWidth: "978px",
    panelClass: "default",
  },
  roamMdc: {
    backdropClass: "roam-dialog-backdrop",
    panelClass: "roam-dialog-panel",
    minwidth: "720px",
  },
};